.dot {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
    padding-top: 10px;
    margin-right: 5px;
}

.red {
    background-color: red;
}

.green {
    background-color: green;
}

.yellow {
    background-color: yellow;
}

.orange {
    background-color: orange;
}