* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
img {
    max-width: 100%;
}

table {
    width: 100%;
    margin-top: 10px;
}

.table-header-title{
    text-align: left;
    padding: 10px 0;
    font-weight: 600;
    text-transform: uppercase;
    border-bottom: 3px solid #333;
}

.report-title {
    margin-top: -20px;
}

.data-row {
    width: 100%;
}

.data-key-label {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 5px;
    text-transform: uppercase;
    width: 40%;
    display: inline-block;
}

.data-key-value {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
}

.comments-sections{
    margin-top: 20px;
}

.comments-sections-content {
    background-color: #d2d2d2;
    padding: 10px 20px;
    border-radius: 10px;
    width: 100%;
    margin-top: 10px;
}

.section {
    margin-top: 50px;
}

.separation {
    height: 15px;
    width: 150px;
    background-color: #011634;
}

.wrapper {
    height: 300px;
    position: relative;
}
.graphContainer {
    height: 300px;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 0;
}

.secondGraph {
    height: 300px;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 4;
}