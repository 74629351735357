.fit-input {
    box-sizing: border-box;
    width: 100%;
}

.aqua {
    background-color: #00FFFF;
}

.green {
    background-color: #00FF00;
}

